<template>
	<div>
		<div class="refresh" v-if="!loginType">
			正在導入中，請稍等
			<Loader />
		</div>
		<div class="login" v-else>
			<div class="login-card">
				<img
					class="login-card__logo__img"
					alt="logoIcon"
					src="../../assets/logoIcon.png"
				/>
				<div class="login-card__title">歡迎使用!</div>
				<div class="login-card__title__second">前台點餐登入</div>

				<div class="login-card__body">
					<VTextField
						class="text-field"
						id="account"
						v-model="form.account"
						label="帳號"
					></VTextField>
					<VTextField
						class="text-field"
						id="password"
						v-model="form.password"
						label="密碼"
						type="password"
						@doEnter="login"
					></VTextField>

					<ByCheckbox
						class="remember-me"
						title="記住帳號"
						v-model="isMemoryAccount"
					/>

					<div class="login-btn">
						<ByButton
							stl="main1"
							@click="login"
							:loading="isSubmitLoading"
							:disabled="valid"
							style="color: #000"
							:style="{
								background: !valid ? '#EDB477' : 'rgba(85, 85, 85, 0.3)',
							}"
							>登入</ByButton
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from '@/components/Loader'
import ByIcon from '@/components/Icon.vue'
import VTextField from '@/components/VTextField.vue'
import ByCheckbox from '@/components/Checkbox.vue'
import ByButton from '@/components/Button.vue'

export default {
	name: 'Refresh',
	components: { ByButton, ByCheckbox, VTextField, ByIcon, Loader },
	// props: {},
	data() {
		return {
			firstCheck: true,
			loginType: false,
			form: {
				account: '',
				password: '',
			},
			isSubmitLoading: false,
		}
	},
	computed: {
		storeId() {
			return this.$store.state.user.storeId
		},
		valid() {
			return this.form.account === '' || this.form.password === ''
		},
		isMemoryAccount: {
			get() {
				return this.$store.state.user.isMemoryAccount
			},
			set(checked) {
				this.$store.commit('user/SET_IS_MEMORY_ACCOUNT', checked)
				if (checked === false) {
					this.$store.commit('user/SET_ACCOUNT', '')
				}
			},
		},
		account() {
			return this.$store.state.user.account
		},
	},
	watch: {
		async storeId(e, v) {
			if (this.storeId && this.firstCheck) {
				await this.getCheck()
			}
		},
	},
	created() {},
	mounted() {
		if (this.storeId && this.firstCheck) {
			this.getCheck()
		}
	},
	// beforeDestroy() {},
	methods: {
		async getCheck() {
			this.firstCheck = false
			const qrCodeHash = this.$route.params.random
			const kiosk = this.$route.query.kiosk === qrCodeHash

			if (kiosk) {
				this.$store.commit('kiosk/SET_IS', false)
				this.$store.commit('user/SET_GUEST_ID', null)
				this.openLogin()
				return
			}

			this.$store.commit('kiosk/SET_IS', false)
			await this.goContact()
			await this.$router.replace(`/${this.$route.params.random}/Store`)
		},
		openLogin() {
			this.loginType = true
			if (this.isMemoryAccount === true) {
				this.$set(this.form, 'account', this.account)
			}
		},
		async goContact() {
			this.$api.getUrl().then(res => {
				if (res.data) {
					this.$store.commit('store/SET_URI', res.data)
				}
			})
		},
		async login() {
			this.isSubmitLoading = true
			const data = {
				...this.form,
				storeId: this.storeId,
			}
			await this.$api.user
				.login(data)
				.then(res => {
					if (this.isMemoryAccount) {
						this.$store.commit('user/SET_ACCOUNT', this.form.account)
					}

					this.$store.commit('kiosk/SET_IS', true)
					this.$store.commit('user/SET_GUEST_ID', res.data.account)
					this.$store.commit('user/SET_XSRF_TOKEN', res.xsrfToken)

					this.goContact()
					this.$store.dispatch('socket/initWebSocket')
					this.$router.replace(`/${this.$route.params.random}/Store`)
				})
				.catch(err => {
					this.$alert(err || '帳號/密碼錯誤', 'danger')
				})
				.finally(() => (this.isSubmitLoading = false))
		},
	},
}
</script>

<style lang="sass" scoped>
@keyframes loginImg
  0%
    opacity: 0
    transform: translateX(-50%) scaleY(0)
  100%
    opacity: 1
    transform: translateX(-50%) scaleY(1)

@keyframes loginCard
  0%
    opacity: 0
    transform: translateY(-20px)
  100%
    opacity: 1
    transform: translateY(0px)

@keyframes loginCardTitleSec
  0%
    opacity: 0
    transform: translateX(-100%)

  100%
    opacity: 1
    transform: translateX(0px)


.refresh
  display: flex
  justify-content: center
  align-items: center
  height: 100vh
  font-size: 30px

  .loader
    margin-left: 15px

.login
  width: 100%
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
  background-image: url("../../assets/bg.png")
  background-size: cover
  background-position: center center

  &-card
    width: 450px
    height: 630px
    background: #FFF
    border-radius: 20px
    padding: 40px 20px
    overflow: hidden
    position: absolute
    transition: 0.3s
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)

    &__logo__img
      position: absolute
      opacity: 1
      object-fit: contain
      left: 50%
      height: 45px
      animation: loginImg 1s 0s 1 both

    &__title
      text-align: center
      font-size: 3rem
      font-weight: bold
      margin-top: 80px
      justify-items: center
      color: #1e3e5f
      animation: loginCard 1s 0.2s 1 both
      &__second
        color: #616161
        text-align: center
        font-size: 1.5rem
        margin-top: 15px
        margin-bottom: 20px
        animation: loginCardTitleSec 1s 1s 1 both

    &__body
      padding-left: 40px
      padding-right: 40px
      align-items: center
      margin-top: 50px
      .text-field
        margin-bottom: 20px
      .remember-me
        padding-left: 40px
        margin-bottom: 40px
      .login-btn
        display: flex
        justify-content: center
        align-items: center
        font-size: 1.5rem
        .btn
          border-radius: 100px
          width: 160px
          height: 55px
</style>
